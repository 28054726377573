import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Card from 'components/style/Card';
import PlaceholderCard from 'components/style/PlaceholderCard';

const ModulesOverview = ({ dispatch, modules, course }) => {
  const [toggledModule, setToggledModule] = useState(null);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mb-5">

      {
        modules.length === 0 &&
        <>
          <PlaceholderCard />
          <PlaceholderCard />
        </>
      }

      { modules.map((mod) => (
        <Card
          key={mod.uuid}
          title={mod.title}
          image={mod.cover_source ? process.env.REACT_APP_CDN_URL + mod.cover_source : null}
          link={`/kurse/${mod.course_id}/${mod.uuid}`}
          description={mod.description} />
      ))}

    </div>
  )
}

export default ModulesOverview;
