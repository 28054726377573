import axios from 'axios';

import {
  AUTH_REQUESTED,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  AUTH_LOGOUT
} from '../constants/actions';
import store from '../store';

// When authentication response is success.
function authSuccess(data) {
  storeAuthBundle(data);
  store.dispatch({ type: AUTH_SUCCESS });
}

// When authentication response is failure.
function authFailure(bundle) {
  localStorage.clear();
  store.dispatch({ type: AUTH_FAILURE });
}

// Write auth bundle to localStorage.
function storeAuthBundle(bundle) {
  localStorage.setItem('accessToken', bundle.access_token);
  localStorage.setItem('accessExpiresAt', bundle.expires_at);
  localStorage.setItem('refreshToken', bundle.refresh_token);
  localStorage.setItem('refreshExpiresAt', bundle.expires_at);
}

// Authenticate by email and password
export function authByPassword(email, password) {
  return dispatch => {
    dispatch({ type: AUTH_REQUESTED });

    axios.post(process.env.REACT_APP_USERS_API_URL + '/v1/signin', {
        email: email,
        password: password,
        domain: 'org.reifam.ausbildung',
      })
      .then(function(response) {
        if (response.status === 200) {
          authSuccess(response.data);
        } else {
          authFailure();
        }
      })
      .catch(function(response) {
        authFailure();
      });
  }
}

// Logout the user.
export function logout() {
  return dispatch => {
    dispatch({ type: AUTH_LOGOUT });
    localStorage.clear();
    location.href = "/";
  }
}
