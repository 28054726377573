import React from 'react'
import { connect } from 'react-redux';
import { loadProfile, updateProfile } from '../../actions/profile';

import Button from 'components/style/Button';
import TextInput from 'components/style/TextInput';

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: {
        domain: props.profile.domain,
        country: props.profile.country,
        display_name: props.profile.display_name,
        description: props.profile.description,
        website: props.profile.website,
        phone_number: props.profile.phone_number,
        public: props.profile.public,
      }
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleProfileChange = this.handleProfileChange.bind(this);
  }

  handleProfileChange(event) {
    const { profile } = this.state;

    profile[event.target.name] = event.target.value;

    this.setState({ profile });
  }

  handlePublicChange(event) {
    const { profile } = this.state;

    profile[event.target.name] = !profile.public

    this.setState({ profile });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { dispatch } = this.props;

    dispatch(updateProfile(this.state.profile));
  }

  render() {
    const { profile } = this.state;

    return (
      <form className="w-full" onSubmit={this.handleSubmit}>
        <div className="bg-white rounded-lg overflow-hidden p-5 grid mb-5 gap-6">
          <h3 className="font-bold col-span-6">
            Dein Profil
          </h3>

          <div className="col-span-6">
            <label className="block text-xs font-bold uppercase text-gray-700">
              Angezeigter Name
            </label>
            <TextInput
              value={profile.display_name}
              name="display_name"
              onChange={this.handleProfileChange}
              name="display_name" />
          </div>

          <div className="col-span-3">
            <label className="block text-xs font-bold uppercase text-gray-700">
              Website
            </label>
            <TextInput
              value={profile.website}
              name="website"
              onChange={this.handleProfileChange}
              name="website" />
          </div>

          <div className="col-span-3">
            <label className="block text-xs font-bold uppercase text-gray-700">
              Telefonnummer
            </label>
            <TextInput
              value={profile.phone_number}
              name="phone_number"
              onChange={this.handleProfileChange}
              name="phone_number" />
          </div>

          <div className="col-span-3 sm:col-span-2">
            <label className="block text-xs font-bold uppercase text-gray-700">
              Land
            </label>
            <select
              name="country"
              onChange={this.handleProfileChange}
              value={profile.country}
              className="mt-1 w-full rounded-md block sm:text-sm border p-2 border-gray-400 focus:border-green-800 outline-none bg-transparent">
                <option label=" "></option>
                <option value="DE">DE</option>
                <option value="AT">AT</option>
                <option value="CH">CH</option>
                <option value="LU">LU</option>
                <option value="ES">ES</option>
                <option value="FR">FR</option>
                <option value="PT">PT</option>
                <option value="UK">UK</option>
                <option value="JP">JP</option>
                <option value="US">US</option>
            </select>
          </div>

          <div className="col-span-6">
            <label className="block text-xs font-bold uppercase text-gray-700">
              Beschreibung (max. 500 Zeichen)
            </label>

            <textarea
              value={profile.description}
              name="description"
              maxLength={500}
              onChange={this.handleProfileChange}
              className="mt-1 w-full rounded-lg block sm:text-sm border p-2 border-gray-400 outline-none"
              name="description" />
          </div>

          <div className="col-span-6">
            <label className="inline-block mr-2 text-xs font-bold uppercase text-gray-700">
              Profil öffentlich
            </label>

            <input
              type="checkbox"
              className="inline"
              checked={profile.public}
              name="public"
              onChange={this.handlePublicChange.bind(this)}
            />
          </div>

          <div className="col-span-6 flex items-center justify-center">
            <div className="">
              <Button title="Profil aktualisieren" primary onClick={this.handleSubmit} />
            </div>
          </div>

        </div>
      </form>
    );
  }
}

class ProfileWrapper extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loadProfile());
  }

  render() {
    const { profile } = this.props;

    if (profile.uuid) {
      return <Profile {...this.props} />
    } else {
      return <div>Lade Profil</div>
    }
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.data
  };
}


export default connect(mapStateToProps)(ProfileWrapper);
