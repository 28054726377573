import React from 'react'

import LoginForm from 'components/LoginForm';

class Login extends React.Component {
  render() {
    return(
      <React.Fragment>

        <h1 className="text-xl md:text-2xl xl:text-4xl text-center mt-20 font-bold">
          Bei reifam® anmelden
        </h1>

        <div className="max-w-xs m-auto flex flex-col justify-center items-stretch">

          <div className="mb-5">
          </div>

          <LoginForm />

        </div>

      </React.Fragment>
    );
  }
}

export default Login;
