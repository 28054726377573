import React from 'react'
import Profile from './Profile';
import Avatar from './Avatar';

class Settings extends React.Component {
  render() {
    return (
      <div className="container p-5 flex flex-col m-auto">
        <h1 className="text-bold py-5 text-3xl xl:text-4xl 2xl:text-5xl mb-2">
          Einstellungen
        </h1>

        <p className="bg-white rounded-lg overflow-hidden p-5 mb-5">
          Hier findest du deine Einstellungen für dein Profil
          im Coachfinder. Die Profile werden tagsüber stündlich
          auf der Website synchronisiert. Bei Fragen, wende dich
          gerne jederzeit an uns.
        </p>

        <Profile />

        <Avatar />

      </div>
    );
  }
}

export default Settings;
