import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux';
import Login from '../login'
import NotFound from '../404';
import Reset from '../reset'

import Logo from '../../images/logo.png';

class PublicApp extends React.Component {
  render() {
    return(
      <React.Fragment>

        <header className="flex h-20 items-center px-5">
          <a href="https://www.reifam.org">
            <img src={Logo} alt="reifam.org" style={{ maxWidth: '65px'}} />
          </a>
        </header>

        <main>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/reset" component={Reset} />
            <Route exact path="/" component={Login} />
            <Route component={NotFound} />
          </Switch>
        </main>

      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    router: state.router
  };
}

export default connect(mapStateToProps)(PublicApp);
