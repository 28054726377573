import React from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadCourse, loadModules } from 'actions/courses';
import Octicon, { ChevronLeft, DesktopDownload, Link as LinkIcon } from '@githubprimer/octicons-react';

import VideoPlayer from 'components/VideoPlayer';
import AudioPlayer from 'components/AudioPlayer';

const Module = ({ course, module }) => {
  const audios = module.attachments.filter(a => a.type === "audio");
  const videos = module.attachments.filter(a => a.type === "video");
  const links = module.attachments.filter(a => a.type === "link");
  const downloads = module.attachments.filter(a => a.type === "download");

  return (
    <div className="container flex flex-col m-auto px-5">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-10">

        { module.cover_source &&
          <img
            className="rounded-lg"
            src={process.env.REACT_APP_CDN_URL + module.cover_source} />
        }

        <div>
          <Link to={`/kurse/${course.uuid}`} className="text-md flex items-center">
            <Octicon icon={ChevronLeft} className="mr-2"/> { course.title }
          </Link>

          <h1 className="text-bold pt-2 pb-5 text-2xl xl:text-3xl 2xl:text-4xl">
            { module.title }
          </h1>

          <div className="py-2 rounded leading-normal">
            <div className="text-base">
              { module.description }
            </div>
          </div>

        </div>
      </div>

      {
        videos.length > 0 &&
        <div className="flex flex-col mb-10 bg-white rounded-lg p-5">
          <h3 className="text-lg xl:text-xl mb-3">Videos</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            { videos.map(v =>
              <div key={v.uuid} className="mb-4">
                <VideoPlayer video={v} />
              </div>
            ) }
          </div>
        </div>
      }

      {
        audios.length > 0 &&
        <div className="flex flex-col mb-10 bg-white rounded-lg p-5">
          <h3 className="text-lg xl:text-xl mb-3">Audios</h3>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 gap-5">
            { audios.map(a =>
              <div key={a.uuid} className="mb-4">
                <AudioPlayer audio={a} />
              </div>
            ) }
          </div>
        </div>
      }

      {
        downloads.length > 0 &&
        <div className="flex flex-col mb-10 bg-white rounded-lg p-5">
          <h3 className="text-lg xl:text-xl mb-3">Dateien</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5">
            { downloads.map(d =>
              <a href={d.url}
                target="_blank"
                className="bg-white border rounded-full px-3 py-2 text-center">

                <Octicon icon={DesktopDownload} className="mr-2" />

                {d.title}
              </a>
            ) }
          </div>
        </div>
      }

      {
        links.length > 0 &&
        <div className="flex flex-col mb-10 bg-white rounded-lg p-5">
          <h3 className="text-lg xl:text-xl mb-3">Links</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 cl:grid-cols-3 gap-5">
            { links.map(l =>
              <a 
                target="_blank"
                href={l.source} className="bg-white border rounded-full px-3 py-2 text-center">
                <Octicon icon={LinkIcon} className="mr-2" />
                {l.title}
              </a>
            ) }
          </div>
        </div>
      }
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const course = state.courses.data.find(c => c.uuid === ownProps.match.params.course_id);
  const module = course.modules.find(m => m.uuid === ownProps.match.params.id);

  return {
    course,
    module
  };
}

export default connect(mapStateToProps)(Module);
