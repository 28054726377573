import React from 'react'
import { Link } from 'react-router-dom';

import TextInput from 'components/style/TextInput';

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      success: false,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  handleEmailChange(ev) {
    this.setState({ email: ev.target.value });
  }

  submitForm(event) {
    event.preventDefault();

    // Do not allow empty emails.
    if (!this.state.email.length) { return; }

    // Store the context.
    const that = this;
    const payload = {
      referrer: 'https://my.reifam.org',
      email: this.state.email
    }

    // Start the user reset action in the API.
    window.usersApi.post('/v1/reset_password', payload)
      .then((response) => {
        if (response.status === 202) {
          that.setState({ success: true });
          return Promise.resolve();
        }

        return Promise.reject();
      });
  }

  renderSuccess() {
    return (
      <div className="text-center max-w-2xl m-auto mt-20">
        <h1 className="text-xl md:text-2xl lg:text-4xl mb-5">
          Das hat geklappt!
        </h1>
        <p className="text-lg mb-10">
          Deine Anfrage zum Zurücksetzen deines Passworts
          ist bei uns eingegangen. Sofern du ein aktives reifam-Konto
          besitzt, wirst du innerhalb weniger Sekunden eine E-Mail mit weiteren
          Instruktionen erhalten.
        </p>
        <Link
          className="bg-primary rounded-full text-xl px-6 py-2"
          to="/">
          Zurück zum Start
        </Link>
      </div>
    )
  }

  renderForm() {
    return (
      <div className="text-center max-w-2xl m-auto mt-20 p-5">
        <h1 className="text-xl md:text-2xl lg:text-4xl mb-5">
          Passwort zurücksetzen
        </h1>
        <p className="text-lg mb-10">
          Solltest du dein Passwort vergessen oder noch kein Passwort
          für die App vergeben haben, kannst du dies hier tun. Gib die
          E-Mail-Adresse deines Accounts an und du erhältst in wenigen Sekunden
          eine E-Mail mit den weiteren Anweisungen.
        </p>
        <form
          className="pt-6 pb-8 mb-4 max-w-xl flex flex-col justify-center items-stretch m-auto"
          onSubmit={this.submitForm}>
          <div className="mb-4">
            <TextInput
              id="email"
              className="border py-2 px-3 w-full"
              placeholder="E-Mail-Adresse"
              value={this.state.email}
              onChange={this.handleEmailChange}
              required
              type="email" />
          </div>
          <div className="flex items-center justify-between">
            <Link className="inline-block align-baseline font-bold text-sm
                             bg-transparent hover:bg-white rounded-full py-2 px-6
                             hover:opacity-75" to="/">
              Zurück
            </Link>

            <button className="bg-primary hover:bg-opacity-75
                               font-bold py-2 px-6 rounded-full focus:outline-none
                               focus:shadow-outline"
              color="primary"
              type="submit">
              Beantragen
            </button>

          </div>
        </form>
      </div>
    );
  }

  render() {
    return (
      this.state.success ? this.renderSuccess() : this.renderForm()
    );
  }
}

export default Form;
