import {
  PROFILE_SUCCESS,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAILURE,
} from '../constants/actions';
import { toast } from 'react-toastify';

export function loadProfile() {
  return dispatch => {
    // Call the club api to find the users.
    window.usersApi.get(`/v1/me/profile`)
      .then(function(response) {
        dispatch({ type: PROFILE_SUCCESS, data: response.data });
      })
      .catch(function(response) {
        // TODO
      });
  }
}

export function updateProfile(data) {
  return dispatch => {
    dispatch({ type: PROFILE_UPDATE_REQUEST });

    // Call the club api to find the users.
    window.usersApi.patch(`/v1/me/profile`, data)
      .then(function(response) {
        if (response.status === 200) {
          toast.success('Profil erfolgreich geändert!');
          dispatch({ type: PROFILE_UPDATE_SUCCESS, data: response.data });
        } else {
          dispatch({ type: PROFILE_UPDATE_FAILURE });
          const { message } = response.data;
          toast.error('Error: ' + message);
        }
      })
      .catch(function(error) {
        toast.error('Fehler. Falsche Angaben?');
        dispatch({ type: PROFILE_UPDATE_FAILURE });
      });
  }
}

export function updateAvatar(formData) {
  return (dispatch) => {
    dispatch({ type: PROFILE_UPDATE_REQUEST });

    window.usersApi.post(`v1/me/profile/avatar`, formData)
      .then((res) => {
        if (res.status === 201) {
          toast.success('Profil erfolgreich geändert!');
          dispatch({ type: PROFILE_UPDATE_SUCCESS, data: res.data});
        } else {
          dispatch({ type: PROFILE_UPDATE_FAILURE });
          toast.error('Fehler beim Hochladen.');
        }
      })
      .catch((err) => {
        dispatch({ type: PROFILE_UPDATE_FAILURE });
        toast.error('Fehler beim Hochladen.');
      })
  }
}
