import React from 'react';
import Button from 'components/style/Button';
import Fade from 'react-reveal/Fade';

import { Link } from 'react-router-dom';

const Card = ({ title, description, image, link }) => {
  return (
    <Fade>
      <Link to={link}>
        <div className="bg-white w-full rounded-lg overflow-hidden">

          <div
            className="bg-cover bg-center flex items-center justify-center"
            style={{
            height: '250px',
            backgroundImage: image ? `url("${image}")` : 'none' }}>

            { !image &&
              // When no image is available we show the module title.
              <span className="text-2xl">
                { title.split(' ')[0] }
              </span>
            }

          </div>

          <div className="p-4">
            <h4 className="mb-2">{ title }</h4>
            <p>{ description.slice(0, 120) } ...</p>
          </div>
        </div>
      </Link>
    </Fade>
  );
}

export default Card;
